import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views

import UserProfileLite from "./views/_UserProfileLite";
import UploadDocument from "./views/UploadDocument";
import Errors from "./views/_Errors";
import DoLogout from "./views/DoLogout";
import ProductsView from "./views/ProductsView";
import AddNewReport from "./views/_AddNewReport";
import EntityList from "./views/EntityList"
import AppList from "./views/AppList";
import ChatView from "./views/ChatView";
import AppResultView from "./views/AppResultView";
import DashboardView from "./views/DashboardView";
import BrandDetails from "./views/BrandDetails";
import CategoryDetails from "./views/CategoryDetails";
import SerieDetails from "./views/SerieDetails";
import SubCategoryDetails from "./views/SubCategoryDetails";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/products" />
  },

  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/upload",
    layout: DefaultLayout,
    component: UploadDocument
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },  
  {
    path: "/apps",
    layout: DefaultLayout,
    component: AppList
  },
  {
    path: "/document-chat",
    layout: DefaultLayout,
    component: ChatView
  },
  {
    path: "/app-result",
    layout: DefaultLayout,
    component: AppResultView
  },
  {
    path: "/add-new-report",
    layout: DefaultLayout,
    component: AddNewReport
  },
  {
    path: "/products",
    layout: DefaultLayout,
    component: ProductsView
  },
  {
    path: "/brand-details",
    layout: DefaultLayout,
    component: BrandDetails
  },
  {
    path: "/serie-details",
    layout: DefaultLayout,
    component: SerieDetails
  },
  {
    path: "/category-details",
    layout: DefaultLayout,
    component: CategoryDetails
  },
  {
    path: "/subcategory-details",
    layout: DefaultLayout,
    component: SubCategoryDetails
  },
  {
    path: "/entities",
    layout: DefaultLayout,
    component: EntityList
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: DashboardView
  },
  {
    path: "/do-logout",
    layout: DefaultLayout,
    component: DoLogout
  }
];
