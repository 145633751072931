export default function () {
  return [   
    {
      title: "Cataloghi",
      htmlBefore: '<i class="material-icons">upload</i>',
      to: "/upload",
    },
    
    {
      title: "Prodotti",
      htmlBefore: '<i class="material-icons">description</i>',
      to: "/products",
    },
    {
      title: "Preventivo AI",
      htmlBefore: '<i class="material-icons">code</i>',
      to: "/document-chat",
    },
    {
      title: "Preventivi Salvati",
      htmlBefore: '<i class="material-icons">memory</i>',
      to: "/apps",
    },
   /*
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">logout</i>',
      to: "/do-logout",
    },
    */
  ];
}
