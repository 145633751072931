import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "../components/shops-overview/SmallStats";
import UsersOverview from "../components/shops-overview/ShopsOverview";
import ShopsByCategory from "../components/shops-overview/ShopsByCategoryLite";
import UtilsCallBe from '../utils/utilCallBe';




const ShopOverview = ({ keycloak }) => {
  const [shopOverview, setShopOverview] = useState({
    profile: null,
    smallStats: null,
    monthStats: null,
    shopsByCategory:null
  })


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let profile = await keycloak.loadUserProfile();
        let url = window.env.BACKEND_URL+ "chats/" + profile.attributes.tg_chat_id[0] + "/reports/top-5-category?time_interval=always";
        let smallStatsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', null, keycloak.token)                    
        url = window.env.BACKEND_URL+ "chats/" + profile.attributes.tg_chat_id[0] + "/reports/history-month";
        let monthStatsFromBe= await UtilsCallBe.callBeWithFetch(url, 'GET', null, keycloak.token)
        url = window.env.BACKEND_URL+ "chats/" + profile.attributes.tg_chat_id[0] + "/reports/shops-by-category-lite?time_interval=always";
        let shopsByCategoryFromBe= await UtilsCallBe.callBeWithFetch(url, 'GET', null, keycloak.token)
        
        setShopOverview({ ...shopOverview,smallStats: smallStatsFromBe.data, monthStats: monthStatsFromBe.data, shopsByCategory:shopsByCategoryFromBe.data })

  } catch (error) {
    console.error(error)
  }
})();
  }, []);


if ((!shopOverview.smallStats) || (!shopOverview.monthStats) || (!shopOverview.shopsByCategory)) {
  return <div>Loading...</div>;
}


return (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
    </Row>
    <h6>Trend TOP 5: da sempre</h6>

    <Row>
      {shopOverview.smallStats.map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
          //percentage={stats.percentage}
          //increase={stats.increase}
          //decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row>

    <Row>
      {/* Users Overview */}
      <Col lg="8" md="12" sm="12" className="mb-4">
        <UsersOverview keycloak={keycloak} chartData={shopOverview.monthStats} />
      </Col>

      {/* Users by Device */}
      <Col lg="4" md="6" sm="12" className="mb-4">
        <ShopsByCategory chartData={shopOverview.shopsByCategory}/>
      </Col>


    </Row>
  </Container>
);
}



export default ShopOverview;
