/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, CardHeader, CardImg, CardTitle, CardFooter,
  Breadcrumb, BreadcrumbItem,
} from "shards-react";

import { useHistory } from "react-router-dom";
import NewApp from "../components/new-app/NewApp"
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const BrandDetails = ({ keycloak }) => {
  const [brandDetails, setBrandDetails] = useState({
    brand_name: null,
    series: []    
  })

  let history = useHistory();


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let brand_name = window.location.pathname.split("/")[2];
        let url = window.env.BACKEND_URL + "brands/" + brand_name + "/series";
        let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        if (responseFromBe.data != null) {
          setBrandDetails({
            ...brandDetails,
            brand_name: brand_name,
            series: responseFromBe.data.series,
            product_counter: responseFromBe.data.product_counter,
            subcategory_counter: responseFromBe.data.subcategory_counter,
            category_counter: responseFromBe.data.category_counter,
          })
        }

      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (!brandDetails.brand_name) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">
      <Breadcrumb>
        <BreadcrumbItem ><a href="/documents">brands</a></BreadcrumbItem>
        <BreadcrumbItem active>{brandDetails.brand_name}</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle={brandDetails.brand_name + " Series"} className="text-sm-left" />
          </Col>
        </Row>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            {brandDetails.series.map((entry, index) => {
              return (
                <Col>
                  <Card style={{ maxWidth: "300px" }}>
                    <CardHeader>{entry}</CardHeader>                    
                    <CardBody>
                      <CardTitle>{entry}</CardTitle>

                      <Button onClick={() => history.push("/serie-details/" + entry + "?brand_name=" + brandDetails.brand_name)}>Details &rarr;</Button>
                    </CardBody>                    
                  </Card>
                </Col>
              )
            })
            }
          </Row>
        </Container>
      </Container>
    </Container>

  );

}

export default BrandDetails;
