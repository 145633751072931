
const fetch = require('node-fetch');


const utilsCallBe = {

    callBeWithFetch: async (url, method, token, body) => {
        try {
            console.log("Try to " + method + " " + url)
            let options = {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
            if (method === 'POST') {
                options.body = JSON.stringify(body)
            }

            let rawResponse = await fetch(url, options);
            const content = await rawResponse.json();
            return content;
        } catch (error) {
            console.log(error);
            throw error;
        }
    },


    callBeForUploadDocument: async (url, method, token, new_document) => {
        try {
            let form_data = new FormData()
            form_data.append("file_content", new_document.file_content)
            form_data.append("file_name", new_document.file_name)
            form_data.append("file_size", new_document.file_size)
            form_data.append("file_type", new_document.file_type)

            console.log("Try to " + method + " " + url)
            let options = {
                method: method,
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                },
                body: form_data
            }
            let rawResponse = await fetch(url, options);
            const content = await rawResponse.json();
            return content;
        } catch (error) {
            console.log(error);
            throw error;
        }

    },

    callBeWithFetchForRaw: async (url, method, body, token) => {
        try {
            console.log("Try to " + method + " " + url)
            let options = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
            if (method === 'POST') {
                options.body = JSON.stringify(body)
            }

            let rawResponse = await fetch(url, options);
            const content = await rawResponse.buffer();
            return content;
        } catch (error) {
            console.log(error);
            throw error;
        }
    },


    
}

export default utilsCallBe;