import React, { useState, useEffect } from "react";
import UtilsCallBe from '../utils/utilCallBe';
import PageTitle from "../components/common/PageTitle";
import {
  Container, Button, Row, Col, Card, Form, FormGroup, FormInput, InputGroup, InputGroupAddon,
  Collapse, InputGroupText, CardBody, Modal, FormTextarea, ModalBody, ModalHeader, ModalFooter
} from "shards-react";
import { useHistory } from "react-router-dom";


const AppList = ({ keycloak }) => {
  const [appList, setApplist] = useState({
    user_presales: [],
    open_modal_description: false,
    open_modal_pdf: false,
    selected: null,
    details: null,
    collapses: [],
    entry_to_download: null
  });


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let url = window.env.BACKEND_URL + "presales";
        let resultFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
        let presales_len = resultFromBe.data.user_presales.length;

        setApplist({
          ...appList,
          user_presales: resultFromBe.data.user_presales,
          collapses: Array(presales_len).fill(false),
          selected: resultFromBe.data.user_presales[0]
        })
      } catch (error) {
        console.log("Error while trying to retrieve apss from backend")
        console.error(error)
      }
    })();
  }, []);



  const toggleDescriptionModal = () => {
    setApplist({ ...appList, open_modal_description: false, open_modal_result: false })
  }

  const togglePdfModal = () => {
    setApplist({ ...appList, open_modal_pdf: false, open_modal_result: false })
  }

  const creaPdf = async (entry) => {   
    await keycloak.updateToken(30);
    let request_url = window.env.BACKEND_URL + "presales-pdf/" + entry.request_id + "/" + entry.presale_id;
    let options = {
      method: "GET",
      headers: {
        'Accept': 'application/pdf',
        'Content-Type': 'application/pdf',
        'Authorization': 'Bearer ' + keycloak.token
      }
    };

    try {
      let rawResponse = await fetch(request_url, options);
      if (!rawResponse.ok) throw new Error(`Errore: ${rawResponse.status}`);

      const blob = await rawResponse.blob();
      if (blob.type !== 'application/pdf') {
        console.error('Il blob non è un PDF valido');
        return;
      }

      const objurl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objurl;
      link.download = 'preventivo.pdf'; // Opzionale: specifica il nome del file

      // Append to body and trigger download
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(objurl); // Rimuovi l'oggetto URL dopo il download
    } catch (error) {
      console.error('Errore durante la creazione del PDF:', error);
    }
  };


  const saveDetails = async () => {
    let intestatario = document.getElementById("#intestatario").value
    let localita = document.getElementById("#localita").value
    let indirizzo = document.getElementById("#indirizzo").value
    let telefono = document.getElementById("#telefono").value
    let sconto = document.getElementById("#sconto").value

    let details = {
      "presale_id": appList.selected.presale_id,
      "request_id": appList.selected.request_id,
      "customer_name": intestatario,
      "customer_location": localita,
      "customer_address": indirizzo,
      "customer_phone": telefono,
      "customer_sconto": sconto
    }

    await keycloak.updateToken(30);
    let url = window.env.BACKEND_URL + "presales-details";
    let resultFromBe = await UtilsCallBe.callBeWithFetch(url, 'POST', keycloak.token, details)
    window.location.reload();

  }

  const handleIntestatarioChange = (event) => {
    let detailsUpdated = appList.details;
    detailsUpdated.customer_name = event.target.value
    setApplist({
      ...appList,
      details: detailsUpdated
    })
  }

  const handleLocalitaChange = (event) => {
    let detailsUpdated = appList.details;
    detailsUpdated.customer_location = event.target.value
    setApplist({
      ...appList,
      details: detailsUpdated
    })
  }

  const handleIndirizzoChange = (event) => {
    let detailsUpdated = appList.details;
    detailsUpdated.customer_address = event.target.value
    setApplist({
      ...appList,
      details: detailsUpdated
    })
  }

  const handleTelefonoChange = (event) => {
    let detailsUpdated = appList.details;
    detailsUpdated.customer_phone = event.target.value
    setApplist({
      ...appList,
      details: detailsUpdated
    })
  }

  const handleScontoChange = (event) => {
    let detailsUpdated = appList.details;
    detailsUpdated.customer_sconto = event.target.value
    setApplist({
      ...appList,
      details: detailsUpdated
    })
  }



  if (!appList) {
    return <div>Loading...</div>;
  } else


    return (
      <div>
        <Modal size="lg" open={appList.open_modal_description} toggle={toggleDescriptionModal}>
          <ModalHeader>Presentazione preventivo </ModalHeader>
          <ModalBody>
            <FormTextarea style={{ minWidth: "600px", minHeight: "800px" }} value={appList.selected ? appList.selected.presale_description : null}>
            </FormTextarea>

          </ModalBody>
          <ModalFooter><h6>Id richiesta:{appList.selected ? appList.selected.request_id : ""}</h6></ModalFooter>
        </Modal>

        <Modal size="lg" open={appList.open_modal_pdf} toggle={togglePdfModal}>
          <ModalHeader>Modifica Dettagli preventivo </ModalHeader>
          <ModalBody>
            <h6>Compila il form con i dati del destinatario del preventivo</h6>
            <Form>
              <FormGroup>
                <label htmlFor="#intestatario">Intestatario Preventivo</label>
                <FormInput id="#intestatario" placeholder="Nome Cognome" value={appList.details != null ? appList.details.customer_name : ""} onChange={handleIntestatarioChange} />
              </FormGroup>
              <FormGroup>
                <label htmlFor="#localita">Località</label>
                <FormInput id="#localita" placeholder="Inserisci Citta e Paese" value={appList.details != null ? appList.details.customer_location : ""} onChange={handleLocalitaChange} />
              </FormGroup>
              <FormGroup>
                <label htmlFor="#indirizzo">Indirizzo</label>
                <FormInput id="#indirizzo" placeholder="Inserisci Indirizzo" value={appList.details != null ? appList.details.customer_address : ""} onChange={handleIndirizzoChange} />
              </FormGroup>
              <FormGroup>
                <label htmlFor="#telefono">Telefono</label>
                <FormInput id="#telefono" placeholder="Inserisci numero di telefono" value={appList.details != null ? appList.details.customer_phone : ""} onChange={handleTelefonoChange} />
              </FormGroup>
              <label htmlFor="#telefono">Sconto in euro</label>

              <InputGroup className="mb-2">
                <InputGroupAddon type="prepend">
                  <InputGroupText>€</InputGroupText>
                </InputGroupAddon>
                <FormInput id="#sconto" placeholder="Sconto" value={appList.details != null ? appList.details.customer_sconto : ""} onChange={handleScontoChange} />
              </InputGroup>
            </Form>
            <Button
              onClick={async () => {
                await saveDetails();

              }}
              theme="warning"
              className="mb-2 mr-1"

            >
              Salva
            </Button>

          </ModalBody>
          <ModalFooter><h6>Id richiesta:{appList.selected ? appList.selected.request_id : ""}</h6></ModalFooter>


        </Modal>

        <Container fluid className="main-content-container px-8">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle sm="4" title="" subtitle="Preventivi Salvati" className="text-sm-left" />
            </Col>
          </Row>


          <Container fluid className="main-content-container px-4">
            {/* Page Header */}

            <Row>
              <Col>
                <Card large className="mb-4">
                  <CardBody className="p-0 pb-3">

                    {
                      appList.user_presales.map((entry, presales_index) => {
                        return (
                          <div>
                            <table className="table mb-0">
                              <thead className="bg-light">
                                <tr>
                                  <th scope="col" className="border-0">
                                    Id
                                  </th>
                                  <th scope="col" className="border-0">
                                    Destinatario
                                  </th>
                                  <th scope="col" className="border-0">
                                    Totale
                                  </th>
                                  <th scope="col" className="border-0">
                                    Data creazione
                                  </th>
                                  <th scope="col" className="border-0">
                                    Scheda
                                  </th>
                                  <th scope="col" className="border-0">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={presales_index}>
                                  <td>{entry.presale_id}</td>
                                  <td>{entry.customer_name}</td>
                                  <td>{entry.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', })}</td>
                                  <td>{entry.created_at}</td>
                                  <td style={{ whiteSpace: "break-spaces" }}>{entry.presale_sheet}</td>
                                  <td>
                                    <Button
                                      theme="primary"
                                      className="mb-2 mr-1"
                                      onClick={() => {
                                        let current = appList.collapses[presales_index]
                                        let new_collapses = Array(appList.user_presales.length).fill(false);
                                        new_collapses[presales_index] = !current;
                                        setApplist({
                                          ...appList,
                                          selected: entry,
                                          collapses: new_collapses
                                        })
                                      }}>
                                      {!appList.collapses[presales_index] ? "Apri" : "Chiudi"}
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setApplist({
                                          ...appList,
                                          open_modal_description: true,
                                          selected: entry,
                                        })
                                      }}
                                      theme="info"
                                      className="mb-2 mr-1"
                                    >
                                      Presentazione
                                    </Button>

                                    <Button
                                      onClick={async () => {
                                        await keycloak.updateToken(30);
                                        let url = window.env.BACKEND_URL + "presale-details/" + entry.request_id + "/" + entry.presale_id;
                                        let detailsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
                                        setApplist({
                                          ...appList,
                                          open_modal_pdf: true,
                                          details: detailsFromBe.data.presale_details,
                                          selected: entry
                                        })
                                      }}
                                      theme="warning"
                                      className="mb-2 mr-1"
                                    >
                                      Intestatario
                                    </Button>

                                    <Button
                                      theme="danger"
                                      className="mb-2 mr-1"
                                      onClick={()=>{    
                                        creaPdf(entry)
                                        }}
                                    >
                                      PDF
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <Collapse open={appList.collapses[presales_index]}>
                              <div className="p-3 mt-3 border rounded">
                                <h5>Richiesta testuale:</h5>
                                <span style={{ whiteSpace: "break-spaces" }}>{appList.user_presales[presales_index].user_request}</span>
                                <h5></h5>
                                <h5>Prodotti:</h5>
                                <table className="table mb-0">
                                  <thead className="bg-light">
                                    <tr>
                                      <th scope="col" className="border-0">
                                        Quantità
                                      </th>
                                      <th scope="col" className="border-0">
                                        Codice
                                      </th>
                                      <th scope="col" className="border-0">
                                        Categoria
                                      </th>
                                      <th scope="col" className="border-0">
                                        Sotto Categoria
                                      </th>
                                      <th scope="col" className="border-0">
                                        Prezzo
                                      </th>
                                      <th scope="col" className="border-0">
                                        Brand
                                      </th>
                                      <th scope="col" className="border-0">
                                        Descrizione
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {appList.selected.products.map((entry, product_index) => {
                                      return (
                                        <tr key={product_index}>
                                          <td>{appList.user_presales[presales_index].quantity[product_index]}</td>
                                          <td>{entry.codice}</td>
                                          <td>{entry.categoria}</td>
                                          <td>{entry.sottocategoria}</td>
                                          <td>{entry.prezzo.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', })}</td>
                                          <td>{entry.brand}</td>
                                          <td style={{ whiteSpace: "break-spaces" }}>{entry.text}</td>
                                        </tr>
                                      )
                                    })
                                    }
                                  </tbody>
                                </table>
                                <h5>Totale:{appList.selected.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', })}</h5>
                              </div>
                            </Collapse>
                          </div>
                        )
                      })
                    }


                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </Container>


      </div>



    )
};

export default AppList;
