import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import Checkout from "../components/user-profile-lite/Checkout";

const UserProfileLite = ({ keycloak }) => {
  const [userDetail, setUserDetail] = useState({
    profile: null
  });

  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        await keycloak.loadUserProfile().then(profile => {
          setUserDetail({ ...userDetail, profile: profile });
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  if (!userDetail.profile) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="User Profile"
          subtitle="Overview"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="4">
          <UserDetails profile={userDetail.profile} keycloak={keycloak} />
        </Col>
        <Col lg="8">
          <UserAccountDetails profile={userDetail.profile} />
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Checkout profile={userDetail.profile} keycloak={keycloak}  />
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfileLite;
