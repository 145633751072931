/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, FormRadio, Button, Breadcrumb, BreadcrumbItem, FormSelect, FormTextarea, Alert
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const ProductsView = ({ keycloak }) => {
  const [productsView, setProductsView] = useState({
    products: [],
    brands: [],
    categories: [],
    subcategories: [],
    selected_row: null,
    selected_mode: "FILTER",
    selected_brand: "",
    selected_category: "",
    selected_subcategory: "",
    alert_visible: false,
    alert_text: "",
    alert_theme: "danger",
    text_to_search: "",
    result_text:""
  })





  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let url = window.env.BACKEND_URL + "brands";
        let brandsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)


        url = window.env.BACKEND_URL + "categories";
        let categoriesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        if (categoriesFromBe.data != null) {
          setProductsView({
            ...productsView,
            categories: categoriesFromBe.data.categories,
            brands: brandsFromBe.data.brands
          })
        }

      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  const changeSearchMode = (mode) => {
    setProductsView({
      ...productsView, selected_mode: mode
    })
  }

  const handleBrandChange = (event) => {
    setProductsView({
      ...productsView, selected_brand: event.target.value
    })
  }


  const updateSubCategories = async (event) => {
    let category_name = event.target.value
    await keycloak.updateToken(30);
    let url = window.env.BACKEND_URL + "categories/" + category_name;
    let subCategoriesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

    setProductsView({
      ...productsView,
      subcategories: subCategoriesFromBe.data.subcategories,
      selected_category: category_name,
      selected_subcategory: subCategoriesFromBe.data.subcategories[0]
    })
  }

  const handleSubCategoryChange = (event) => {
    setProductsView({
      ...productsView, selected_subcategory: event.target.value
    })
  }

  const searchByFilter = async () => {
    let selected_brand = productsView.selected_brand;
    let selected_category = productsView.selected_category;
    let selected_subcategory = productsView.selected_subcategory;

    if (productsView.selected_mode == "FILTER" &&
      selected_brand == "" &&
      selected_category == "" &&
      selected_subcategory == "") {
      setProductsView({
        ...productsView,
        alert_visible: true,
        alert_text: "Inserire almeno un criterio di ricerca",
        alert_theme: "danger"
      })
    }
    else {
      let filter = {
        selected_brand: selected_brand,
        selected_category: selected_category,
        selected_subcategory: selected_subcategory
      }

      await keycloak.updateToken(30);
      let url = window.env.BACKEND_URL + "search-by-filter";
      let productsFromBe = await UtilsCallBe.callBeWithFetch(url, 'POST', keycloak.token, filter)
      manageResultFromBe(productsFromBe)
    }
  }

  const searchByText = async () => {
    let search = {
      "text_to_search": productsView.text_to_search
    }
    await keycloak.updateToken(30);
    let url = window.env.BACKEND_URL + "search-by-text";
    let productsFromBe = await UtilsCallBe.callBeWithFetch(url, 'POST', keycloak.token, search)
    manageResultFromBe(productsFromBe)
  }

  const manageResultFromBe = (productsFromBe) => {
    if (productsFromBe.error == null) {
      if (productsFromBe.data.products.length == 0) {
        setProductsView({
          ...productsView,
          alert_visible: true,
          alert_text: "Nessun prodotto trovato",
          alert_theme: "info",
          products: [],
          result_text: "0 prodotti"
        })
      } else {
        setProductsView({
          ...productsView,
          alert_visible: true,
          alert_text: "La ricerca ha trovato "+productsFromBe.data.products.length +" prodotto/i",
          alert_theme: "success",
          products: productsFromBe.data.products,
          result_text: productsFromBe.data.products.length +" prodotto/i"
        })
      }
    } else {
      setProductsView({
        ...productsView,
        alert_visible: true,
        alert_text: "Errore nel recupero dei dati",
        alert_theme: "danger",
        result_text: "0 prodotti"
      })
    }
  }



  const alertDismiss = () => {
    setProductsView({
      ...productsView, alert_visible: false
    })
  };

  const hadleFormTextAreaChange = (event) => {
    setProductsView({
      ...productsView,
      text_to_search: event.target.value
    })
  }

  return (
    <Container fluid className="main-content-container px-8">
      <Breadcrumb>
        <BreadcrumbItem active>Prodotti</BreadcrumbItem>
      </Breadcrumb>

      <Alert
        theme={productsView.alert_theme}
        dismissible={alertDismiss}
        open={productsView.alert_visible}>
        {productsView.alert_text}
      </Alert>


      <Container fluid className="main-content-container px-4">
        <p className="mb-2">Seleziona la modalità di ricerca:</p>
        <FormRadio
          name="FILTER"
          checked={productsView.selected_mode === "FILTER"}
          onChange={() => {
            changeSearchMode("FILTER");
          }}
        >
          Filtro
        </FormRadio>
        <FormRadio
          name="TEXT"
          checked={productsView.selected_mode === "TEXT"}
          onChange={() => {
            changeSearchMode("TEXT");
          }}
        >
          Ricerca Testuale
        </FormRadio>
      </Container>


      <Container fluid className="main-content-container px-2">
        <Row noGutters>
          <Col lg="3" md="6" sm="12" className="mb-8">BRAND</Col>
          <Col lg="3" md="6" sm="12" className="mb-8">CATEGORIA</Col>
          <Col lg="3" md="6" sm="12" className="mb-8">SOTTO CATEGORIA</Col>
        </Row>

        <Row noGutters>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <FormSelect
              onChange={handleBrandChange}
              disabled={productsView.selected_mode == "TEXT"}>
              <option value="TUTTI">TUTTI</option>
              {productsView.brands.map((entry) => {
                return (
                  <option value={entry}>{entry}</option>
                )
              })
              }
            </FormSelect>
          </Col>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <FormSelect
              onChange={updateSubCategories}
              disabled={productsView.selected_mode == "TEXT"}>
              <option value="TUTTI">TUTTI</option>
              {productsView.categories.map((entry) => {
                return (
                  <option value={entry}>{entry}</option>
                )
              })
              }
            </FormSelect>
          </Col>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <FormSelect
              onChange={handleSubCategoryChange}
              disabled={productsView.selected_mode == "TEXT"}>
              {productsView.subcategories.map((entry) => {
                return (
                  <option value={entry}>{entry}</option>
                )
              })
              }
            </FormSelect>
          </Col>

          <p></p>
        </Row>
        <Row>
          <Col lg="9" md="6" sm="12" className="mb-8">
            <Row noGutters>
              <Col lg="9" md="6" sm="12" className="mb-8">
                Inserisci una descrizione testuale del prodotto che stai cercando (può generare falsi positivi)
              </Col>
            </Row>

            <FormTextarea
              onChange={hadleFormTextAreaChange}
              disabled={productsView.selected_mode == "FILTER"}
            ></FormTextarea>
          </Col>
        </Row>
        <p></p>
        <Row>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <Button
              onClick={() => {
                setProductsView({
                  ...productsView, result_text:"Ricerca in corso"
                })
                if (productsView.selected_mode == "FILTER") {
                  searchByFilter()
                } else {
                  searchByText()
                }
              }}
            >Cerca
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <p>{productsView.result_text}</p>
          </Col>
        </Row>


      </Container>

      <p></p>

      <Container fluid className="main-content-container px-4">
        <Row>
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  CODICE
                </th>
                <th scope="col" className="border-0">
                  BRAND
                </th>
                <th scope="col" className="border-0">
                  PREZZO
                </th>
                <th scope="col" className="border-0">
                  CATEGORIA
                </th>
                <th scope="col" className="border-0">
                  SOTTOCATEGORIA
                </th>
                <th scope="col" className="border-0">
                  SCHEDA
                </th>
              </tr>
            </thead>
            <tbody>
              {productsView.products.map((entry, index) => {
                return (
                  <tr key={index}>
                    <td>{entry.codice}</td>
                    <td>{entry.brand}</td>
                    <td>{entry.prezzo.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', })}</td>
                    <td>{entry.categoria}</td>
                    <td>{entry.sottocategoria}</td>
                    <td>
                      <Button
                        theme="warning"
                        className="mb-2 mr-1"
                        onClick={() => {
                          setProductsView({
                            ...productsView,
                            selected: entry
                          })
                        }}>
                        Dettaglio
                      </Button>
                    </td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
        </Row>

      </Container>
    </Container>

  );

}

export default ProductsView;
