import React from "react";
import { Nav } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";

const NavBarNav = ({keycloak}) => {
  return (
    <Nav navbar className="border-left flex-row">
      <Notifications keycloak={keycloak} />
      <UserActions keycloak={keycloak} />
    </Nav>
  )
}
export default NavBarNav
