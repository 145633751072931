import React, { useState, useEffect, useHistory } from "react";
import UtilsCallBe from '../utils/utilCallBe';
import PageTitle from "../components/common/PageTitle";
import { Container, Button, Row, Col, Card, CardBody, FormTextarea } from "shards-react";


const AppResultView = ({ keycloak }) => {
  const [appResultView, setAppResultView] = useState({
    entities: [],
    app_uid: window.location.pathname.split("/")[2],
    result_uid: null

  });

  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30)
        let url = window.env.BACKEND_URL + "apps/" + appResultView.app_uid + "/result";
        let entitiesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
        let entities = [];
        entitiesFromBe.data.entities.forEach(element => {
          entities.push(JSON.parse(element))
        });

        setAppResultView({
          ...appResultView,
          entities: entities,
        })

      } catch (error) {
        console.log("Error while trying to retrieve data from backend")
        console.error(error)
      }
    })();
  }, []);







  if (!appResultView) {
    return <div>Loading...</div>;
  } else
    return (
      
        <Container fluid className="main-content-container px-8">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle sm="4" title="" subtitle="Entità estratte dal documento" className="text-sm-left" />
            </Col>
          </Row>



          <Container fluid className="main-content-container px-4">
            {/* Page Header */}

            <Row>
              <Col>
                <Card large className="mb-4">
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            Modello
                          </th>
                          <th scope="col" className="border-0">
                            Dimensioni
                          </th>
                          <th scope="col" className="border-0">
                            Potenza
                          </th>
                          <th scope="col" className="border-0">
                            Peso
                          </th>
                          <th scope="col" className="border-0">
                            Linea
                          </th>
                          <th scope="col" className="border-0">
                            Descrizione
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          appResultView.entities.map((entry, index) => {
                            return (
                              <tr key={index}>
                                <td>{entry.nome_modello}</td>
                                <td>{entry.dimensioni}</td>
                                <td>{entry.potenza}</td>
                                <td>{entry.peso}</td>
                                <td>{entry.linea}</td>
                                <td>{entry.descrizione}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>

      

    )
};

export default AppResultView;
