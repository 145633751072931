import React, { useState } from "react";
import {
  Container,
  Button,
  FormTextarea,
  Form,
  FormGroup,
  FormRadio,
  Row,
  Alert
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';




const Errors = ({ keycloak }) => {
  const [errorState, setErrorState] = useState({
    platform: null,
    textAreaContent: null,
    responseFromBe: null,
    btnClicked: false
  })


  const handleTextAreaChange = (event) => {
    setErrorState({ ...errorState, textAreaContent: event.target.value })
  }

  const handleButtonClick = async () => {    
    await setErrorState({ ...errorState, btnClicked: true})
    console.log("Sending error report to backend...")
    await keycloak.updateToken(30);
    await keycloak.loadUserProfile()
      .then(async (profile) => {
        let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/errors";
        let errorObj = {
          platform: errorState.platform,
          content: errorState.textAreaContent
        }
        let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'POST', errorObj, keycloak.token)
        console.log(responseFromBe)
        setErrorState({ ...errorState, responseFromBe: responseFromBe,btnClicked: true})
      })
      .catch(error => {
        console.log(error);
      })
  }




  return (
    <div>
      {errorState.responseFromBe ?
        <Alert theme='success' className="mb-0">
          <i className="fa fa-info mx-2"></i> La tua segnalazione è stata inviata con successo
      </Alert>
        : null
      }
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Segnala un errore del sistema" subtitle="Errors" className="text-sm-left" />
        </Row>
        <Form>
          <FormGroup>
            <p className="mb-2">Seziona il canale sul quale hai riscontrato il problema:</p>




            <FormRadio
              name="telegram_bot"
              checked={errorState.platform === "telegram_bot"}
              onChange={() => { setErrorState({ ...errorState, platform: "telegram_bot" }) }}
            >Telegram Bot
            </FormRadio>

            <FormRadio
              name="web_app"
              checked={errorState.platform === "web_app"}
              onChange={() => { setErrorState({ ...errorState, platform: "web_app" }) }}
            >Web App
            </FormRadio>

            <FormTextarea size="lg" onChange={handleTextAreaChange} />
            <br />
          </FormGroup>

        </Form>
        <Button
          pill
          disabled={errorState.btnClicked}
          onClick={handleButtonClick}
          type="submit"
          size='lg'
        >Send</Button>
      </Container >
    </div>
  );

}

export default Errors;
