import React, { useState, useEffect } from "react";
import PageTitle from "../components/common/PageTitle";
import {
  Container,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  InputGroup,
  FormSelect,
  Button,
  FormCheckbox,
  Alert
} from "shards-react";
import UtilsCallBe from '../utils/utilCallBe';
import { useHistory } from "react-router-dom";






const AddNewReport = ({ keycloak }) => {
  let history = useHistory();
  const [newReportState, setNewReportState] = useState({
    isFormValid: true,
    is_entry_saved: false,
    categories: null,
    all_categories_checked: true,
    categories_for_report: [],
    channel_mail_checked: false,
    channel_telegram_checked: true,
    frequency: "Mensile",
    button_disabled: false,
    alert_message:""

  });


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        await keycloak.loadUserProfile()
          .then(profile => {
            let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/categories";
            return UtilsCallBe.callBeWithFetch(url, 'GET', null, keycloak.token)
          })
          .then(categoryFromBe => {
            setNewReportState({ ...newReportState, categories: categoryFromBe.data.data })
          }).catch(error => {

          })
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);



  const handleFrequencyChange = (event) => {
    setNewReportState({ ...newReportState, frequency: event.target.value })
  }

  const handleChannelMailChange = (event) => {
    setNewReportState({ ...newReportState, channel_mail_checked: !newReportState.channel_mail_checked })
  }

  const handleChannelTelegramChange = (event) => {
    setNewReportState({ ...newReportState, channel_telegram_checked: !newReportState.channel_telegram_checked })
  }

  const handleAllCategoriesChange = (event) => {
    setNewReportState({ ...newReportState, all_categories_checked: !newReportState.all_categories_checked })
  }

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  const handleSingleCategoriesChange = (event) => {
    let clickedCategory = null;
    if (event.type === "change") {
      clickedCategory = event.target.labels[0].innerText;
    } else if (event.type === "click") {
      clickedCategory = event.target.nextElementSibling.innerText
    }
    let new_categories_for_report = [];
    new_categories_for_report = newReportState.categories_for_report;

    if (!new_categories_for_report.includes(clickedCategory))
      new_categories_for_report.push(clickedCategory);
    else if (new_categories_for_report.includes(clickedCategory))
      removeItemOnce(new_categories_for_report, clickedCategory)
  

  setNewReportState({ ...newReportState, categories_for_report: new_categories_for_report })
}



const handleButtonClick = async (event) => {

  if ((newReportState.all_categories_checked === false)) {
    if (newReportState.categories_for_report.length === 0) {
      setNewReportState({
        ...newReportState, isFormValid: false,alert_message:"Seleziona almeno una categoria."
      })
      return
    }
  }

  if ((newReportState.channel_mail_checked === false) &&
    (newReportState.channel_telegram_checked === false)) {
    setNewReportState({
      ...newReportState, isFormValid: false,alert_message:"Seleziona almeno un canale di invio."
    })
    return

  }
  console.log("Try to save report on BE")

  let newReportObj = {
    all_categories_checked: newReportState.all_categories_checked,
    categories_for_report: newReportState.categories_for_report,
    frequency: newReportState.frequency,
    channel_mail_checked: newReportState.channel_mail_checked,
    channel_telegram_checked: newReportState.channel_telegram_checked
  }
  await keycloak.updateToken(30);
  let profile = await keycloak.loadUserProfile()
  let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/report-config";
  UtilsCallBe.callBeWithFetch(url, 'POST', newReportObj, keycloak.token)
    .then(resultFromBe => {
      console.log(resultFromBe);
      setNewReportState({
        ...newReportState, is_entry_saved: true, button_disabled: true
      })
      history.push('/reports')
    }).catch(error => {
      console.log("Impossible to save shop entry")
    })
}




if (newReportState.categories == null) {
  return <div>Loading...</div>;
} else



  return (
    <div>
      {!newReportState.isFormValid ?
        <Alert theme='warning' className="mb-0">
          <i className="fa fa-info mx-2"></i> {newReportState.alert_message}
        </Alert>
        : null
      }

      {newReportState.is_entry_saved ?
        <Alert theme='success' className="mb-0">
          <i className="fa fa-info mx-2"></i> Il report è stato salvato con successo!
        </Alert>
        : null}

      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}


        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Aggiungi un nuovo report" subtitle="report" className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="8" className="mb-4">
            <Card medium className="mb-6">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Inserisci i parametri del nuovo report</h6>
              </CardHeader>

              <ListGroup flush>

                <ListGroupItem className="p-0 px-3 pt-3">
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feImportoLabel">Categorie</label>
                      <InputGroup className="mb-3">
                        <FormCheckbox
                          toggle
                          checked={newReportState.all_categories_checked}
                          //onChange={setNewReportState({...newReportState, all_categories_checked:!newReportState.all_categories_checked})}>
                          onChange={handleAllCategoriesChange}>
                          Tutte le categorie
                        </FormCheckbox>
                      </InputGroup>
                    </Col>
                  </Row>

                  {!newReportState.all_categories_checked ?
                    newReportState.categories.map(category => {
                      return (
                        <FormCheckbox
                          checked={newReportState.categories_for_report.includes(category.name)}
                          onChange={handleSingleCategoriesChange}
                        >{category.name} </FormCheckbox>
                      )
                    })
                    : null}



                  <Row form>
                    <Col md="18" className="form-group">
                      <label htmlFor="feInputLabelCategory">Frequenza</label>
                      <InputGroup className="mb-3">
                        <FormSelect
                          size="md"
                          onChange={handleFrequencyChange}
                          id="feInputCategory">
                          <option key='monthly'>Mensile</option>
                          <option key='weekly'>Settimanale</option>                          
                        }
                      </FormSelect>
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="18" className="form-group">
                      <label htmlFor="feInputLabelCategory">Canale</label>
                      <FormCheckbox
                        checked={newReportState.channel_telegram_checked}
                        onChange={handleChannelTelegramChange}>
                        Telegram
                      </FormCheckbox>
                      <FormCheckbox
                        checked={newReportState.channel_mail_checked}
                        onChange={handleChannelMailChange}>
                        Mail
                      </FormCheckbox>
                      
                    </Col>
                  </Row>



                  <Col md="18" className="form-group">
                    <Button
                      disabled={newReportState.button_disabled}
                      pill
                      size='lg'
                      onClick={handleButtonClick}
                      type="submit">Salva</Button>
                  </Col>
                  <br />


                </ListGroupItem>
              </ListGroup>

            </Card>




          </Col>
        </Row>

      </Container >
    </div>
  )
}

export default AddNewReport;
