import React, { useState, useEffect } from "react";
import UtilsCallBe from '../utils/utilCallBe';
import PageTitle from "../components/common/PageTitle";
import { Container, Button, Row, Col, Card, CardBody, FormTextarea } from "shards-react";
import { useHistory } from "react-router-dom";
import { Iframe } from "react-iframe";




const DashboardView = ({ keycloak }) => {
    const [entityList, setEntityList] = useState({
        entities: [],
        selected_entity_index: null
    });
    let history = useHistory();






    if (!entityList) {
        return <div>Loading...</div>;
    } else





        return (
            <Container fluid className="main-content-container px-8">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <Col>
                        <PageTitle sm="4" title="" subtitle="Entity to extract from document" className="text-sm-left" />
                    </Col>
                </Row>


                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <iframe
                        src="http://localhost:3001/d-solo/paqK7w-Iz/fridge-dashboard?orgId=1&refresh=5s&from=1713970947944&to=1713971247944&panelId=6"
                        width="450"
                        height="200"
                        frameborder="0">

                    </iframe>
                </Container>
            </Container>

        )
};

export default DashboardView;
