/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardImg, CardTitle, CardFooter,
  Breadcrumb, BreadcrumbItem,
} from "shards-react";

import { useHistory } from "react-router-dom";
import NewApp from "../components/new-app/NewApp"
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const SerieDetails = ({ keycloak }) => {
  const [serieDetails, setSerieDetails] = useState({
    brand_name: null,
    serie_name: null,
    categories: []    
  })

  let history = useHistory();


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let serie_name = window.location.pathname.split("/")[2];
        let brand_name = window.location.search.split("?")[1].split("=")[1]
        if (serie_name != "") {
          let url = window.env.BACKEND_URL + "brands/" + brand_name + "/series/" + serie_name + "/categories";

          let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

          if (responseFromBe.data != null) {
            setSerieDetails({
              ...serieDetails,
              brand_name: brand_name,
              serie_name: serie_name,
              categories: responseFromBe.data.categories              
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (!serieDetails) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">
      <Breadcrumb>
        <BreadcrumbItem ><a href="/documents">brands</a></BreadcrumbItem>
        <BreadcrumbItem ><a href={"/brand-details/" + serieDetails.brand_name}>{serieDetails.brand_name}</a> </BreadcrumbItem>
        <BreadcrumbItem active>{serieDetails.serie_name}</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="Categories" className="text-sm-left" />
          </Col>
        </Row>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            {serieDetails.categories.map((entry, index) => {
              return (
                <Col>
                  <Card style={{ maxWidth: "300px" }}>
                    <CardHeader>{entry}</CardHeader>
                    <CardBody>
                      <CardTitle>{entry}</CardTitle>

                      <Button onClick={() => history.push("/category-details/" + entry + "?brand_name=" + serieDetails.brand_name + "&serie_name=" + serieDetails.serie_name)}>Details &rarr;</Button>
                    </CardBody>                    
                  </Card>
                </Col>
              )
            })
            }
          </Row>
        </Container>
      </Container>
    </Container>

  );

}

export default SerieDetails;
