import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, } from "react-router-dom";

import routes from "./routes";
import ShopOverview from "./views/_ShopOverview";
import { DefaultLayout } from "./layouts";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/style.min.css";
import Keycloak from 'keycloak-js';

const App = () => {

  const [appState, setAppState] = useState({
    keycloak: null,
    authenticated: false
  })

  useEffect(() => {
    (async () => {
      try {
        //const keycloak = Keycloak('/keycloak.json');   
        const keycloak = new Keycloak({
          url: "https://identity.software-si.it",
          realm: "document",
          clientId: "demo-client-fe"          
      });     
        keycloak.init({ onLoad: 'login-required' })
          .then(authenticated => {
            setAppState({ keycloak: keycloak, authenticated: authenticated })
          })

      } catch (error) {
        console.error(error)
      }
    })();
  }, []);



  return (
    appState.keycloak && appState.authenticated ?
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Route
            key='1'
            path="/shop-overview"
            component={(props => {
              return (
                <DefaultLayout {...props} keycloak={appState.keycloak}>
                  <ShopOverview keycloak={appState.keycloak} />
                </DefaultLayout>
              );})}
          />  
        <div>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props => {
                  return (
                    <route.layout keycloak={appState.keycloak}>
                      <route.component keycloak={appState.keycloak} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
      </Router>
      :
      <div>Loading...</div>


  )

};

export default App;

/**
 *
 *
 *
 */