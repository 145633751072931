import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";


const UserActions = ({ keycloak }) => {

  const [userState, setUserState] = useState({
    visible: false,
    profile: null
  });


  const toggleUserActions = () => {
    setUserState({
      ...userState, visible: !userState.visible
    });
  }

  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        keycloak.loadUserProfile()
          .then(profile => {
            setUserState({
              ...userState,
              profile: profile
            })
          }).catch(error => {

          })

      } catch (error) {
        console.error(error)
      }
    })();
  }, []);

  if (!userState.profile) {
    return <div>Loading...</div>;
  } else


    return (
      <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">

          <span className="d-none d-md-inline-block">{
            userState.profile.username
          }

          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={userState.visible}>
          
          <DropdownItem tag={Link} to="user-profile-lite">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="do-logout" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
}


export default UserActions



