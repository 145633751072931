import React, { useState, useEffect } from "react";
import UtilsCallBe from '../utils/utilCallBe';
import PageTitle from "../components/common/PageTitle";
import { Container, Button, Row, Col, Card, CardBody, FormTextarea } from "shards-react";
import { useHistory } from "react-router-dom";





const EntityList = ({ keycloak }) => {
  const [entityList, setEntityList] = useState({
    agents: [],
    selected_entity_index: null
  });
  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30)
        let url = window.env.BACKEND_URL + "template-agents";
        let agentsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        setEntityList({
          ...entityList,
          agents: agentsFromBe.data.agents,
        })
      } catch (error) {
        console.log("Error while trying to retrieve data from backend")
        console.error(error)
      }
    })();
  }, []);





  if (entityList.agents.length == 0) {
    return <div>Loading...</div>;
  } else





    return (
      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="Agenti AI" className="text-sm-left" />
          </Col>
        </Row>


        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            <Col>
              <Card large className="mb-4">
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Nome Agente
                        </th>
                        <th scope="col" className="border-0">
                          Dettaglio
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        entityList.agents.map((entry, index) => {
                          return (
                            <tr key={index}>
                              <td>{entry.class_name}</td>
                              <td>
                                {entry.status == null ?
                                  <Button
                                    onClick={() => {
                                      setEntityList({
                                        ...entityList,
                                        selected_entity_index: index
                                      })
                                    }}
                                    theme="warning"
                                    className="mb-2 mr-1">
                                    Dettaglio
                                  </Button> :
                                  <Button
                                    onClick={() => {
                                      setEntityList({
                                        ...entityList,
                                        open_modal_edit: true,
                                        entry_to_edit: entry
                                      })
                                    }}
                                    theme="info"
                                    className="mb-2 mr-1">
                                    Vai
                                  </Button>
                                }
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            <FormTextarea  style={{maxWidth: "620px", minHeight: "320px"}} value=
              {entityList.selected_entity_index == null ?
                ""
                :
                "Descizione:\n"+entityList.agents[entityList.selected_entity_index].description+"\n\nDettaglio:"+entityList.agents[entityList.selected_entity_index].long_description
              }>
            </FormTextarea>

            <Col>

            </Col>
          </Row>
        </Container>
      </Container>

    )
};

export default EntityList;
