/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardImg, CardTitle, CardFooter,
  Breadcrumb, BreadcrumbItem,
} from "shards-react";

import { useHistory } from "react-router-dom";
import NewApp from "../components/new-app/NewApp"
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const CategoryDetails = ({ keycloak }) => {
  const [categoryDetails, setCategoryDetails] = useState({
    brand_name: "___",
    category_name: "___",
    serie_name: "___",
    subcategories: [],
    product_counter: 0,
    subcategory_counter: 0,
    category_counter: 0
  })

  let history = useHistory();


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let category_name = window.location.pathname.split("/")[2];
        let brand_name = window.location.search.split("?")[1].split("&")[0].split("=")[1];
        let serie_name = window.location.search.split("?")[1].split("&")[1].split("=")[1];

        if (category_name != "") {
          let url = window.env.BACKEND_URL + "brands/" + brand_name + "/series/" + serie_name + "/categories/" + category_name + "/subcategories";

          let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

          if (responseFromBe.data != null) {
            setCategoryDetails({
              ...categoryDetails,
              brand_name: brand_name,
              category_name: category_name,
              serie_name: serie_name,
              subcategories: responseFromBe.data.subcategories,
              product_counter: responseFromBe.data.product_counter,
              subcategory_counter: responseFromBe.data.subcategory_counter,
              category_counter: responseFromBe.data.category_counter,
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (!categoryDetails) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">
      <Breadcrumb>
        <BreadcrumbItem ><a href="/documents">brands</a></BreadcrumbItem>
        <BreadcrumbItem ><a href={"/brand-details/" + categoryDetails.brand_name}>{categoryDetails.brand_name}</a></BreadcrumbItem>
        <BreadcrumbItem ><a href={"/serie-details/" + categoryDetails.serie_name}>{categoryDetails.serie_name}</a></BreadcrumbItem>
        <BreadcrumbItem active>{categoryDetails.category_name}</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="sotto-categorie" className="text-sm-left" />
          </Col>
        </Row>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            {categoryDetails.subcategories.map((entry, index) => {
              return (
                <Col>
                  <Card style={{ maxWidth: "300px" }}>
                    <CardHeader>{entry}</CardHeader>                    
                    <CardBody>
                      <CardTitle>{entry}</CardTitle>

                      <Button onClick={() => history.push("/subcategory-details/" + entry + "?brand_name=" + categoryDetails.brand_name + "&serie_name=" + categoryDetails.serie_name + "&category_name=" + categoryDetails.category_name)}>Details &rarr;</Button>
                    </CardBody>
                    <CardFooter>{categoryDetails.category_counter} categories <br />
                      {categoryDetails.subcategory_counter}  sub-categories <br />
                      {categoryDetails.product_counter}  products</CardFooter>
                  </Card>
                </Col>
              )
            })
            }
          </Row>
        </Container>
      </Container>
    </Container>

  );

}

export default CategoryDetails;
