import React, { useState, useEffect } from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import UtilsCallBe from '../../../../utils/utilCallBe';

const Notifications = ({ keycloak }) => {
  const [notificationsState, setNotificationsState] = useState({
    visible: false
  })


  const toggleNotifications = () => {
    setNotificationsState({
      ...notificationsState,
      visible: !notificationsState.visible
    });
  }

  const formatDate = (dateFromBackend) => {
    let tmp = dateFromBackend.split("T");
    return tmp[0] + " alle " + tmp[1].split(".")[0]
  }

  const getIconsFromType = (type) => {
    switch (type) {
      case "new-bot-release":
        return "chat"

      case "new-webapp-release":
        return "web"

      case "new-report":
        return "attach-file"

      case "upgrade-need":
        return "upgrade";

      case "limit-warning":
        return "warning"


      default:
        return "chat"
    }
  }
  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  const handleNotificationClick = async (notification) => {
    if (notificationsState.notificationsIds.includes(notification._id)) {
      await keycloak.updateToken(30);
      let profile = await keycloak.loadUserProfile()

      console.log("Try to set notification " + notification._id + " as checked");
      let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/notifications"// + notification._id;
      let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'POST', notification, keycloak.token);

      if (responseFromBe.error == null) {
        let newNotificationsIds = notificationsState.notificationsIds;
        removeItemOnce(newNotificationsIds, notification._id);
        setNotificationsState({
          ...notificationsState,
          notificationsIds: newNotificationsIds
        })
      }
    }
  }

  const getColor = (notification) => {
    let to_edit = notificationsState.notificationsIds.find(elem => elem === notification._id)
    if (to_edit != null)
      return { background: "#ffeeee" }
    return { background: "#ffffff" }

  }

  useEffect(() => {
    (async () => {
      try {

        await keycloak.updateToken(30);
        await keycloak.loadUserProfile()
          .then(async profile => {
            //retrieve notification
            let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/notifications?last_n=5";
            let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', null, keycloak.token);
            let notificationsIds = responseFromBe.data.data.filter(notification =>
              notification.checked === false
            ).map(notification => notification._id)
            setNotificationsState({
              ...notificationsState,
              responseFromBe: responseFromBe,
              notificationsIds: notificationsIds
            })
          }).catch(error => {
            console.log("Error while trying to retrieve data from backend")
            console.error(error)
          })
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (notificationsState.responseFromBe == null)
    return "Loading...."

  return (
    <NavItem className="border-right dropdown notifications">
      <NavLink
        className="nav-link-icon text-center"
        onClick={toggleNotifications}
      >
        <div className="nav-link-icon__wrapper">
          <i className="material-icons">&#xE7F4;</i>
          {notificationsState.notificationsIds.length > 0 ?
            < Badge pill theme="danger">
              {notificationsState.notificationsIds.length}
            </Badge>
            : null
          }
        </div>
      </NavLink>
      <Collapse
        open={notificationsState.visible}
        className="dropdown-menu dropdown-menu-small"
      >
        {notificationsState.responseFromBe.data.data.map(notification => {
          return <DropdownItem
            onClick={(event) => handleNotificationClick(notification)}
            style={getColor(notification)}
          >
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">{getIconsFromType(notification.type)}</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category">{notification.type}</span>
              <p>
                {notification.message}
              </p>
              <p>
                {formatDate(notification.created_at)}
              </p>
            </div>
          </DropdownItem>

        })}
        <DropdownItem className="notification__all text-center">
          View all Notifications
          </DropdownItem>
      </Collapse>
    </NavItem >
  );

}
export default Notifications