/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardImg, CardTitle, CardFooter,
  Breadcrumb, BreadcrumbItem,
} from "shards-react";

import { useHistory } from "react-router-dom";
import NewApp from "../components/new-app/NewApp"
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const SubCategoryDetails = ({ keycloak }) => {
  const [subCategoryDetails, setSubCategoryDetails] = useState({
    brand_name: "___",
    category_name: "___",
    serie_name: "___",
    products: [],
    product_counter: 0,
    subcategory_counter: 0,
    category_counter: 0
  })

  let history = useHistory();


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let brand_name = window.location.search.split("?")[1].split("&")[0].split("=")[1];
        let serie_name = window.location.search.split("?")[1].split("&")[1].split("=")[1];
        let category_name = window.location.search.split("?")[1].split("&")[2].split("=")[1]
        let subcategory_name = window.location.pathname.split("/")[2];

        if (category_name != "") {
          let url = window.env.BACKEND_URL + "brands/" + brand_name + "/series/" + serie_name + "/categories/" + category_name + "/subcategories/" + subcategory_name + "/products";

          let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

          if (responseFromBe.data != null) {
            setSubCategoryDetails({
              ...subCategoryDetails,
              brand_name: brand_name,
              category_name: category_name,
              serie_name: serie_name,
              products: responseFromBe.data.products
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (subCategoryDetails.products.length == 0) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">
      <Breadcrumb>
        <BreadcrumbItem ><a href="/documents">brands</a></BreadcrumbItem>
        <BreadcrumbItem ><a href={"/brand-details/" + subCategoryDetails.brand_name}>{subCategoryDetails.brand_name}</a></BreadcrumbItem>
        <BreadcrumbItem ><a href={"/serie-details/" + subCategoryDetails.serie_name}>{subCategoryDetails.serie_name}</a></BreadcrumbItem>
        <BreadcrumbItem active>{subCategoryDetails.category_name}</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="products" className="text-sm-left" />
          </Col>
        </Row>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            {subCategoryDetails.products.map((entry, index) => {
              return (
                <Col>
                  <Card style={{ maxWidth: "450px" }}>
                    <CardHeader>{entry.codice_identificativo}<br/>prezzo: {entry.prezzo}</CardHeader>                    
                    <CardImg src="https://place-hold.it/300x200" />
                    <CardBody>
                      <CardTitle>{entry.codice}</CardTitle>                      
                    </CardBody>
                    <CardFooter>
                      {Object.keys(entry).map((objectKey) => {
                        if (objectKey != "content")
                          return (
                            <h6> {objectKey}: {entry[objectKey]}</h6>
                          )
                      })
                      }

                    </CardFooter>
                  </Card>
                </Col>
              )
            })
            }
          </Row>
        </Container>
      </Container>
    </Container>

  );

}

export default SubCategoryDetails;
