import React, { useState, useEffect } from "react";
import PageTitle from "../components/common/PageTitle";
import {
  Container, Card, ListGroup, ListGroupItem, Row, Col, Button,
  Alert, Modal, ModalBody, ModalFooter, ModalHeader, FormTextarea, InputGroupAddon, FormSelect,
  InputGroupText, FormInput, InputGroup, FormRadio
} from "shards-react";
import UtilsCallBe from '../utils/utilCallBe';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";







const UploadDocument = ({ keycloak }) => {

  const [uploadDocument, setUploadDocument] = useState({
    is_form_valid: true,
    button_disabled: false,
    is_entry_saved: false,
    file_content: null,
    file_size: null,
    file_name: null,
    file_type: null,
    nome_catalogo: "",
    catalogs: [],
    open_modal_chat: false,
    open_modal_details: false,
    selected: null,
    selectedUploadMode: "PDF",
    selectedUploadText: "Caricamento singolo da PDF",
    brands: [],
    categories: [],
    subcategories:[],
    selected_brand:"",
    selected_category:"",
    selected_subcategory:""
    });

  
  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);
        let url = window.env.BACKEND_URL + "catalogs/" + uploadDocument.selectedUploadMode;
        let catalogsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        url = window.env.BACKEND_URL + "brands";
        let brandsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        url = window.env.BACKEND_URL + "categories";
        let categoriesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        url = window.env.BACKEND_URL + "categories/"+categoriesFromBe.data.categories[0];
        let subcategoriesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
        subcategoriesFromBe.data.subcategories = subcategoriesFromBe.data.subcategories.filter(item => item !== "TUTTI");
        
        setUploadDocument({
          ...uploadDocument,
          catalogs: catalogsFromBe.data.catalogs,
          brands: brandsFromBe.data.brands,
          categories: categoriesFromBe.data.categories,
          subcategories: subcategoriesFromBe.data.subcategories,
          selected_brand:brandsFromBe.data.brands[0],
          selected_category:categoriesFromBe.data.categories[0],
          selected_subcategory:subcategoriesFromBe.data.subcategories[0]
        })
      } catch (error) {
        console.log("Error while trying to retrieve apss from backend")
        console.error(error)
      }
    })();
  }, [uploadDocument.is_entry_saved, uploadDocument.selectedUploadMode]);

  const handleNomeCatalogoChange = (event) => {
    setUploadDocument({
      ...uploadDocument,
      nome_catalogo: event.target.value
    });
  }

  const handleBrandChange = (event) => {
    setUploadDocument({
      ...uploadDocument,
      selected_brand: event.target.value
    });
  }


  const updateCategoryChange = async (event) => {
    let category_name = event.target.value
    await keycloak.updateToken(30);
    let url = window.env.BACKEND_URL + "categories/" + category_name;
    let subCategoriesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
    subCategoriesFromBe.data.subcategories = subCategoriesFromBe.data.subcategories.filter(item => item !== "TUTTI");

    setUploadDocument({
      ...uploadDocument,
      subcategories: subCategoriesFromBe.data.subcategories,
      selected_category: category_name,
      selected_subcategory: subCategoriesFromBe.data.subcategories[0]
    })
  }


  const handleSubCategoryChange = (event) => {
    setUploadDocument({
      ...uploadDocument,
      selected_subcategory: event.target.value
    });
  }

  const handlePrezzoChange = (event) => {
    setUploadDocument({
      ...uploadDocument,
      prezzo: event.target.value
    });
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      let file = e.target.files[0]
      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = function () {
        setUploadDocument({
          ...uploadDocument,
          file_content: reader.result,
          file_name: file.name,
          file_size: file.size,
          file_type: file.type
        });
      };
    }
  };


  const handleButtonClick = async (event) => {
    if (uploadDocument.file_content == null) {
      setUploadDocument({
        ...uploadDocument, is_form_valid: false,
      })
    } else {
      console.log("Try to save document on BE")
      await keycloak.updateToken(30);

      let base64 = uploadDocument.file_content.split(";")[1].split(",")[1]
      let new_document = {
        "file_content": base64,
        "file_name": uploadDocument.file_name,
        "file_size": uploadDocument.file_size,
        "file_type": uploadDocument.file_type,
        "nome_catalogo": uploadDocument.nome_catalogo,
      }

      let url;
      if (uploadDocument.selectedUploadMode == "CSV") {
        url = window.env.BACKEND_URL + "catalogs";
      } else {
        url = window.env.BACKEND_URL + "single-product-sheet";
        new_document["brand"] = uploadDocument.selected_brand;        
        new_document["category"] = uploadDocument.selected_category;
        new_document["subcategory"] = uploadDocument.selected_subcategory;
        new_document["price"] = uploadDocument.prezzo;
      }




      UtilsCallBe.callBeWithFetch(url, 'POST', keycloak.token, new_document)
        .then(resultFromBe => {
          console.log(resultFromBe);
          setUploadDocument({
            ...uploadDocument, is_entry_saved: true, button_disabled: false
          })

        }).catch(error => {
          console.log("Impossible to save new catalog")
          setUploadDocument({
            ...uploadDocument, is_entry_saved: false, button_disabled: false
          })
        })
    }
  }


  const changeUploadMode = (user_selection) => {
    let text_to_set;
    if (user_selection == "CSV") {
      text_to_set = "Caricamento massivo da CSV"
    } else {
      text_to_set = "Caricamento singolo da PDF"
    }
    setUploadDocument({
      ...uploadDocument,
      selectedUploadText: text_to_set,
      selectedUploadMode: user_selection
    })
  }


  const toggleDetailsModal = () => {
    setUploadDocument({ ...uploadDocument, open_modal_chat: false, open_modal_details: false })
  }

  const convert_status = (status) => {
    if (status == "RUNNING")
      return "In corso"

    if (status == "DONE")
      return "Completata"

    if (status == "ERROR")
      return "In errore"
  }

  return (
    <div>
      {!uploadDocument.is_form_valid ?
        <Alert theme='warning' className="mb-0">
          <i className="fa fa-info mx-2"></i> Check your data!
        </Alert>
        : null
      }

      {uploadDocument.is_entry_saved ?
        <Alert theme='success' className="mb-0">
          <i className="fa fa-info mx-2"></i> Documento salvato con successo
        </Alert>
        : null}


      <Container fluid className="main-content-container px-8 ">
        {/* Page Header */}


        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="" subtitle="Upload Document" className="text-sm-left" />
        </Row>
        <div>
          <p className="mb-2">Seleziona la modalità di caricamento:</p>
          <FormRadio
            name="PDF"
            checked={uploadDocument.selectedUploadMode === "PDF"}
            onChange={() => {
              changeUploadMode("PDF");
            }}
          >
            PDF
          </FormRadio>
          <FormRadio
            name="CSV"
            checked={uploadDocument.selectedUploadMode === "CSV"}
            onChange={() => {
              changeUploadMode("CSV");
            }}
          >
            CSV
          </FormRadio>

          <span>
            <strong>Modalità selezionata:</strong>{" "}
            <span>{uploadDocument.selectedUploadText || "none"}</span>
          </span>
        </div>

        <Row>
          <Col lg="8" className="mb-4">
            <Card medium className="mb-6">
              <InputGroup>
                <InputGroupAddon flush type="prepend">
                  <InputGroupText style={{ minWidth: "120px", borderRightWidth: "medium" }}>Nome Catalogo</InputGroupText>
                </InputGroupAddon>
                <FormInput placeholder="Inserire il nome scelto per il catalogo" onChange={handleNomeCatalogoChange} />
              </InputGroup>

              {uploadDocument.selectedUploadMode == "PDF" ?
                <InputGroup>
                  <InputGroupAddon flush type="prepend">
                    <InputGroupText style={{ minWidth: "120px", borderRightWidth: "medium" }}>Brand      </InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    onChange={handleBrandChange}>
                    {uploadDocument.brands.map((entry) => {
                      return (
                        <option value={entry}>{entry}</option>
                      )
                    })
                    }
                  </FormSelect>
                </InputGroup>
                : ""}


              {uploadDocument.selectedUploadMode == "PDF" ?
                <InputGroup>
                  <InputGroupAddon flush type="prepend">
                    <InputGroupText style={{ minWidth: "120px", borderRightWidth: "medium" }}>Categoria      </InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    onChange={updateCategoryChange}>
                    {uploadDocument.categories.map((entry) => {
                      return (
                        <option value={entry}>{entry}</option>
                      )
                    })
                    }
                  </FormSelect>
                </InputGroup>
                : ""}

              {uploadDocument.selectedUploadMode == "PDF" ?
                <InputGroup>
                  <InputGroupAddon flush type="prepend">
                    <InputGroupText style={{ minWidth: "120px", borderRightWidth: "medium" }}>SottoCategoria      </InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    onChange={handleSubCategoryChange}>
                    {uploadDocument.subcategories.map((entry) => {
                      return (
                        <option value={entry}>{entry}</option>
                      )
                    })
                    }
                  </FormSelect>
                </InputGroup>
                : ""}
              {uploadDocument.selectedUploadMode == "PDF" ?
                <InputGroup>
                  <InputGroupAddon flush type="prepend">
                    <InputGroupText style={{ minWidth: "120px", borderRightWidth: "medium" }}>Prezzo</InputGroupText>
                  </InputGroupAddon>
                  <FormInput placeholder="Inserire il prezzo di default" onChange={handlePrezzoChange} />
                </InputGroup>
                : ""}


              <ListGroup flush>
                <ListGroupItem className="p-0 px-3 pt-3">
                  <Row form>
                    <>
                      <div>
                        <label htmlFor="file" className="sr-only">
                          Choose a file
                        </label>
                        <input id="file" type="file" onChange={handleFileChange} />
                      </div>
                      {uploadDocument.file_content && (
                        <Row>
                          <Col>
                            <section>
                              File details:
                              <ul>
                                <li>Name: {uploadDocument.file_name}</li>
                                <li>Type: {uploadDocument.file_type}</li>
                                <li>Size: {uploadDocument.file_size} bytes</li>
                              </ul>
                            </section>
                          </Col>
                        </Row>
                      )}
                    </>
                  </Row>

                  <Col md="18" className="form-group">
                    <br />
                    <Button
                      disabled={uploadDocument.button_disabled}
                      pill
                      size='sg'
                      onClick={handleButtonClick}
                      type="submit">Salva</Button>
                  </Col>
                  <br />


                </ListGroupItem>
              </ListGroup>

            </Card>
          </Col>

        </Row>

        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="" subtitle="Cataloghi caricati" className="text-sm-left" />
        </Row>
        <Row>
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Nome catalogo
                </th>
                <th scope="col" className="border-0">
                  Nome file
                </th>
                <th scope="col" className="border-0">
                  Totale prodotti
                </th>
                <th scope="col" className="border-0">
                  Prodotti Scartati
                </th>
                <th scope="col" className="border-0">
                  Data Caricamento
                </th>
                <th scope="col" className="border-0">
                  Stato Elaborazione
                </th>
                <th scope="col" className="border-0">
                  Azioni
                </th>
              </tr>
            </thead>
            <tbody>
              {
                uploadDocument.catalogs.map((entry, index) => {
                  return (
                    <tr key={index}>
                      <td>{entry.catalog_name}</td>
                      <td>{entry.file_name}</td>
                      <td>{entry.products_num}</td>
                      <td>{entry.error_docs ? entry.error_docs.lenght : ""}</td>
                      <td>{entry.created_at}</td>
                      <td>{convert_status(entry.status)}</td>
                      <td><Button
                        theme="info"
                        className="mb-2 mr-1"
                      >
                        Elimina
                      </Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </Row>

      </Container>
    </div >
  )
}

export default UploadDocument;
